import "./style.scss";
import domReady from "@wordpress/dom-ready";
import Typed from "typed.js";

function typer() {
  const els = document.querySelectorAll(".typer");

  //Return if false
  if (!els.length) return null;

  els.forEach(function (typerEffect) {
    let words = typerEffect.getAttribute("data-words");
    var typeWords = words.split(",");

    //Return if false
    if (!typeWords.length) return null;
    var options = {
      strings: typeWords,
      typeSpeed: 80,
      startDelay: 300,
      loop: true,
      backDelay: 1000,
    };
    var typed = new Typed(typerEffect, options);
  });
}

domReady(typer);
